import OpenSansText from "components/custom-texts/open-sans-text";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { sparketBlack } from "utils/constants";
import { open } from "redux/slices/sparket-tutorial-slice";
import { getGroupSecondaryColorOrDefault } from "redux/slices/group-slice";

const TitleBar = () => {
  const dispatch = useAppDispatch();
  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: sparketBlack,
        justifyContent: "space-between",
        height: 45,
      }}
    >
      <OpenSansText
        customStyles={{
          color: secondaryColor,
          fontSize: 30,
        }}
      >
        Help
      </OpenSansText>
      <Button
        style={{
          backgroundColor: sparketBlack,
          border: "none",
          color: "white",
        }}
        onClick={() => dispatch(open())}
      >
        Launch Tutorial
      </Button>
    </div>
  );
};

export default TitleBar;
