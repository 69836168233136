import sparketTutorial1 from "assets/sparket-tutorial/sparket-tutorial-1.png";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import BlockyBlockyMediumText from "components/custom-texts/blocky-medium-text";
import styles from "./styles.module.css";

const PageOneContent = () => {
  return (
    <div className={styles.pageContentContainer}>
      <BlockyHeavyText className={styles.topText}>
        This game features wagering pools with other real players.
      </BlockyHeavyText>
      <img width="90%" src={sparketTutorial1} alt="First slide" />
      <BlockyBlockyMediumText className={styles.bottomText}>
        Odds will update based on the actual volume of wagers on each
        contestant (pari-mutuel).
        <span className={styles.bold}>
          {" "}
          Odds are not locked until the pool closes.
        </span>{" "}
      </BlockyBlockyMediumText>
    </div>
  );
};

export default PageOneContent;
