import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import {BET_STATUS, POOL_STATUS, sparketGold, sparketGreen} from "utils/constants";
import styles from "./styles.module.css";

interface StatusBadgeProps {
  status: number;
  customStyles?: object;
  is_bet: boolean;
}

const getColorAndStatusString = (status: number, is_bet: boolean) => {
  if (BET_STATUS.SOLD && is_bet) {
    return  { backgroundColor: sparketGreen, status: "bet sold" }
  }
  switch (status) {
    case POOL_STATUS.PENDING:
      return { backgroundColor: "lightblue", status: "pending" };
    case POOL_STATUS.OPEN:
      return { backgroundColor: sparketGreen, status: "open" };
    case POOL_STATUS.LIVE:
      return { backgroundColor: sparketGold, status: "in progress" };
    case POOL_STATUS.SETTLED:
      return { backgroundColor: "gray", status: "settled" };
    case POOL_STATUS.CANCELLED:
      return { backgroundColor: "gray", status: "cancelled" };
    default:
      return { backgroundColor: "gray", status: "settled" };
  }
};

const StatusBadge = ({ status, customStyles, is_bet }: StatusBadgeProps) => {
  const displayValues = getColorAndStatusString(status, is_bet);
  return (
    <div
      className={styles.container}
      style={{
        backgroundColor: displayValues.backgroundColor,
        ...customStyles,
      }}
    >
      <BlockyHeavyText>{displayValues.status}</BlockyHeavyText>
    </div>
  );
};

export default StatusBadge;
