import useDeviceDimensions from "hooks/useDeviceDimensions";
import { Contest } from "interfaces/leaderboard/contest";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import { getRequest } from "utils/httpClient";
import { Form } from "react-bootstrap";
import { Phase } from "interfaces/leaderboard/phase";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { getContestsPath } from "utils/backend-path-builders";

interface Props {
  setContest: Dispatch<SetStateAction<any>>;
  setPhase: Dispatch<SetStateAction<any>>;
  phase: Phase | undefined;
}

interface Option {
  id: string;
  label: string;
}

const ContestFilter = ({ setContest, setPhase, phase }: Props) => {
  const [loading, setLoading] = useState(true);
  const [contests, setContests] = useState<Contest[]>([]);
  const [options, setOptions] = useState<Option[]>([]);
  const [phaseOptions, setPhaseOptions] = useState<Phase[]>([]);
  const [showAllPhases, setShowAllPhases] = useState(true);
  const { isMobile } = useDeviceDimensions();
  const eventFilterWidth = isMobile ? "100%" : "50%";
  const groupId = useAppSelector(getGroupState).id;
  const [selectedContest, setSelectedContest] = useState<Option>();

  useEffect(() => {
    if (groupId) {
      fetchOptions();
    }
  }, [groupId]);

  const fetchOptions = () => {
    setLoading(true);
    const path = getContestsPath(groupId);
    getRequest(path).then((data) => {
      const contests = data.filter((contest: Contest) => !contest.is_deleted);
      setContests(contests || []);

      const options: Option[] = contests.map((contest: Contest) => {
        return { id: contest.id, label: contest.name };
      });
      setOptions(options || []);

      if (!selectedContest && options) {
        const activeContest = contests.find(
          (contest: Contest) => contest.is_active
        );
        const selectedOption = options.find(
          (option) => option.id === activeContest.id
        ) as Option;
        if (selectedOption) {
          setSelectedContest(selectedOption);
          setContest(activeContest);
          setPhaseOptions(activeContest.phases);
        }
      }
      setLoading(false);
    });
  };

  if ((loading && !selectedContest) || options.length === 0) {
    return <div></div>;
  }

  return (
    <div style={{ width: eventFilterWidth, paddingRight: 5, paddingBottom: 5 }}>
      <Typeahead
        id="leaderboard-event-filter"
        options={options}
        placeholder="Contest"
        onFocus={() => {
          if (contests.length === 0) {
            fetchOptions();
          }
        }}
        clearButton
        onChange={(filter) => {
          if (filter.length === 0) {
            setContest(undefined);
            setPhaseOptions([]);
            return;
          }
          const contest = contests.find((c: Contest) => (c.id = filter[0].id));
          setContest(contest);

          const phases = contest?.phases || [];
          setPhaseOptions(phases);
        }}
        isLoading={loading}
        defaultSelected={[selectedContest] as Option[]}
      />
      {phaseOptions.length >= 1 && (
        <div style={{ marginTop: 5, display: "flex", flexDirection: "column" }}>
          <Form>
            <Form.Check
              style={{ display: "inline-flex" }}
              inline
              type="radio"
              label={<BlockyBoldText>Overall</BlockyBoldText>}
              name="phases"
              defaultChecked={true}
              onChange={(e) => {
                setShowAllPhases(e.target.checked);
                if (e.target.checked) {
                  setPhase(undefined);
                } else {
                  setPhase(phaseOptions[0]);
                }
              }}
            />
            {phaseOptions.map((phaseOption: any) => {
              const isChecked = phase?.id === phaseOption.id;
              return (
                <Form.Check
                  style={{ display: "inline-flex" }}
                  key={phaseOption.id}
                  inline
                  type="radio"
                  id={phaseOption.id}
                  label={<BlockyBoldText>{phaseOption.name}</BlockyBoldText>}
                  name="phases"
                  checked={showAllPhases ? false : isChecked}
                  onChange={(e) => {
                    setShowAllPhases(false);
                    const phase = phaseOptions.find(
                      (p) => p.id === e.target.id
                    ) as Phase;
                    setPhase(phase);
                  }}
                />
              );
            })}
          </Form>
        </div>
      )}
    </div>
  );
};

export default ContestFilter;
