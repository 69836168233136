import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { Mixpanel } from "hooks/mixPanel";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { sparketBlack } from "utils/constants";
import { useAuth } from "hooks/auth";
import { useAppSelector } from "redux/hooks";
import { getGroupStyles } from "redux/slices/group-slice";

const KeepBettingButton = () => {
  const primaryColor = useAppSelector(getGroupStyles).primary_color;
  const history = useHistory();
  const auth = useAuth();
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button
        onClick={() => {
          Mixpanel.track("Clicked Keep Betting Button", {
            isSignedIn: auth.signedIn,
          });
          history.push("/");
        }}
        style={{
          backgroundColor: primaryColor,
          height: 50,
          borderRadius: 5,
          margin: 30,
          border: "none",
          width: 300,
        }}
      >
        <BlockyBoldText customStyles={{ fontSize: 20, color: sparketBlack }}>
          Keep Betting {">"}
        </BlockyBoldText>
      </Button>
    </div>
  );
};

export default KeepBettingButton;
