import { useEffect, useState } from "react";
import Select from "react-select";
import { getRequest } from "utils/httpClient";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getGroupState, setGroup, setHomePath } from "redux/slices/group-slice";
import PoolItem from "components/pool-item/pool-item";
import styles from "assets/shared-stylesheets/sparket-list-item.module.css";
import Header from "components/header";
import LoadingSpinner from "components/loading-spinner.tsx";
import Headroom from "react-headroom";
import { Pool } from "interfaces/pool";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import "react-bootstrap-typeahead/css/Typeahead.css";
import useDeviceDimensions from "hooks/useDeviceDimensions";
import SetupMixpanel from "app-setup/mixpanel-init";
import { getGroupBySlugPath, getInitializeGamePath, getPoolsPath, } from "utils/backend-path-builders";
import {
  CURRENT_GROUP,
  CURRENT_GROUP_SLUG,
  CURRENT_LOGIN_TOKEN,
  CURRENT_PLAYER_ID,
  CURRENT_USER_ID,
  PENNPLAY_NAME,
  POOL_STATUS_PARAM,
  PROVIDER_PREFIX,
} from "utils/constants";
import useQueryParam from "utils/useQueryParams";
import { open as launchTutorial } from "redux/slices/sparket-tutorial-slice";
import { setGanCredentials, setUserId } from "redux/slices/user-slice";
import { SparketsBanner, ViewMarketplaceButton, } from "@wagerwire/embed/react";
import { local as localStorage } from "../utils/local-storage";
import { getUserGroupSettingsByGroupId, setGroupEventFilter } from "redux/slices/user-settings-slice";
import UserGroupsResponse from "interfaces/user-groups-response";

const Home = (props: any) => {
  const [loadingPools, setLoadingPools] = useState(true);
  const [pools, setPools] = useState<Pool[]>([]);

  const group = useAppSelector(getGroupState);

  const userGroupSettings = useAppSelector(state => getUserGroupSettingsByGroupId(state, group.id));

  SetupMixpanel();

  const dispatch = useAppDispatch();
  const queryParams = useQueryParam();
  useEffect(() => {
    const initGame = async (reduxGroup: any) => {
      if (reduxGroup.id) {
        return;
      }

      const playerId =
        queryParams.get("playerId") || localStorage.getItem(CURRENT_PLAYER_ID);
      let loginToken =
        queryParams.get("loginToken") ||
        localStorage.getItem(CURRENT_LOGIN_TOKEN);
      dispatch(setGanCredentials({ playerId, loginToken }));
      let groupSlug =
        props.match.params?.groupSlug // always sparketSportsBook
        || localStorage.getItem(CURRENT_GROUP_SLUG);
      // let urlPrefix = "gan";
      if (window.location.hostname.includes(PENNPLAY_NAME) || groupSlug === "pennplaylive") {
        groupSlug = PENNPLAY_NAME;
        // urlPrefix = "penn";
      }
      // localStorage.setItem(PROVIDER_PREFIX, urlPrefix);

      const group: UserGroupsResponse = await getRequest(getGroupBySlugPath(groupSlug));
      dispatch(setGroup(group));

      // set up local storage
      localStorage.setItem(CURRENT_PLAYER_ID, playerId || "");
      localStorage.setItem(CURRENT_LOGIN_TOKEN, loginToken || "");
      localStorage.setItem(CURRENT_GROUP, JSON.stringify(group));
      localStorage.setItem(CURRENT_GROUP_SLUG, groupSlug);

      if (queryParams.toString()) {
        dispatch(
          setHomePath(window.location.pathname + "?" + queryParams.toString())
        );
      }
      console.log(process.env.REACT_APP_BACKEND_URL)
      const res = await fetch(
        process.env.REACT_APP_BACKEND_URL + getInitializeGamePath(group.uri),
        {
          method: "POST",
          headers: {
            "x-gan-user-id": playerId || "",
            "x-gan-login-token": loginToken || "",
          },
        }
      );

      const { token, is_new_user, user_id } = await res.json();

      // set new token
      if (token !== "") {
        loginToken = token;
      }

      dispatch(setGanCredentials({ playerId, loginToken }));
      const userId = user_id;
      dispatch(setUserId({ userId }));
      localStorage.setItem(CURRENT_USER_ID, userId);

      if (is_new_user) {
        dispatch(launchTutorial());
      }
    };

    initGame(group);
  }, []);

  useEffect(() => {
    if (group.id === "") {
      setPools([]);
      return;
    }
    const path = getPoolsPath(group.id);
    getRequest(path, {
      queryParams: {
        status: POOL_STATUS_PARAM.OPEN,
        pg: 1,
        page_size: 10000,
        sortBy: "weight:desc,close:asc"
      }
    })
      .then((data) => {
        setPools(data);
      })
      .finally(() => {
        setLoadingPools(false);
      });
  }, [group.id]);

  const eventNames: Set<string> = new Set(
    pools.map((pool) => pool?.event_name || "").filter((name) => name !== "")
  );

  const filteredPools = (() => {
    if (!userGroupSettings.homePagePoolsEventFilter) {
      return pools;
    }
    return pools.filter((pool) => pool.event_name === userGroupSettings.homePagePoolsEventFilter);
  })();

  const { isMobile } = useDeviceDimensions();
  const eventFilterWidth = isMobile ? "100%" : "50%";

  if (loadingPools) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <Headroom>
          <div>
            <Header />
          </div>
        </Headroom>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: 20,
            paddingRight: 20,
            flexWrap: "wrap-reverse",
          }}
        >
          {group.settings.wager_wire && (
            <ViewMarketplaceButton
              groupColor={group.settings.primary_color}
              style={{ width: "100%", padding: 0 }}
              groupId={group.id}
            >
              <SparketsBanner groupColor={group.settings.primary_color} />
            </ViewMarketplaceButton>
          )}
          {pools.length > 0 && (
            <div
              style={{
                width: eventFilterWidth,
                paddingTop: 10,
                paddingBottom: 5,
              }}
            >
              <Select
                isClearable
                placeholder={"Filter by event"}
                options={Array.from(eventNames).map((eventName: string) => ({
                  value: eventName,
                  label: eventName
                }))}
                value={userGroupSettings.homePagePoolsEventFilter ? {
                  value: userGroupSettings.homePagePoolsEventFilter,
                  label: userGroupSettings.homePagePoolsEventFilter
                } : null}
                onChange={(selectedOption) => {
                  if (selectedOption == null) {
                    dispatch(setGroupEventFilter({ groupId: group.id, filter: "" }));
                  } else {
                    dispatch(setGroupEventFilter({ groupId: group.id, filter: selectedOption.value }));
                  }
                }}
              />
            </div>
          )}
        </div>
        {filteredPools && filteredPools.length > 0 ? (
          <>
            {filteredPools.map((pool) => {
              return (
                <PoolItem
                  key={pool.id}
                  styles={styles}
                  pool={pool}
                  link={`/pools/${pool.id}`}
                  showCountDownClock={true} />
              );
            })}
          </>
        ) : (
          <BlockyHeavyText
            customStyles={{
              display: "flex",
              justifyContent: "center",
              color: "white",
            }}
          >
            No pools are currently open. Please check back later.
          </BlockyHeavyText>
        )}
      </>
    );
  }
};

export default Home;
