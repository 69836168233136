import OpenSansText from "components/custom-texts/open-sans-text";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { BET_STATUS, sparketBlack, sparketLightGray } from "utils/constants";
import BetDetailLine from "./bet-detail-line";
import { formatAsCurrency } from "utils/formatter-utils/currency-formatter";
import epochToFormattedDateTime from "utils/formatter-utils/date-formatter";
import ViewPoolLink from "./view-pool-link";
import { Bet } from "interfaces/bet";
import StatusBadge from "components/status-badge";
import { projectedPayout } from "utils/projectedPayoutCalculator";
import LoadingSpinner from "components/loading-spinner.tsx";
import styles from "./styles.module.css";
import ShareButtons from "components/bet-details-page/share-buttons";
import { useAppSelector } from "redux/hooks";
import { getGroupState, GroupUri } from "redux/slices/group-slice";
import { X } from "react-bootstrap-icons";

const winLossAmount = (
  status: number,
  payout: number,
  amount: number,
  groupUri: GroupUri
): string => {
  if (status === BET_STATUS.WIN) {
    return formatAsCurrency(payout, groupUri);
  } else if (status === BET_STATUS.LOSS) {
    return formatAsCurrency(-amount, groupUri);
  }
  return "";
};

interface Props {
  bet: Bet;
  loading: boolean;
  hide?: any;
}

const BetDetailsContent = ({ bet, loading, hide = false }: Props) => {
  const {
    amount,
    odds,
    status,
    pool_id,
    pool_status,
    pool_name,
    pool_close,
    contestant_name,
    payout,
    event_name,
    created,
    event_start,
    ww_listed_amount,
    ww_purchased_amount,
  } = bet;

  const group = useAppSelector(getGroupState);

  if (loading) {
    return <LoadingSpinner />;
  } else {
    const isSettled = [BET_STATUS.WIN, BET_STATUS.LOSS].includes(status);
    let pool_status_badge = pool_status;
    let is_bet = false;
    if (status === BET_STATUS.SOLD) {
      pool_status_badge = status;
      is_bet = true;
    }

    const formattedProjectedPayout = formatAsCurrency(projectedPayout(amount, odds), group.uri);

    return (
      <div>
        <div
          style={{
            backgroundColor: sparketBlack,
            display: "flex",
            justifyContent: "space-between",
          }}
          className={styles.topBarContainer}
        >
          <div></div>
          <div className={styles.shareBetContainer}>
            <ShareButtons bet={bet} />
          </div>
          <X
            onClick={hide}
            style={{ color: "white", cursor: "pointer" }}
            size={40}
          />
        </div>
        <div
          style={{ backgroundColor: sparketLightGray }}
          className={`${styles.center} ${styles.reviewBetTextContainer}`}
        >
          <div style={{ width: 30 }}></div>
          <BlockyBoldText customStyles={{ fontSize: 20 }}>
            Bet Review
          </BlockyBoldText>
          {hide ? (
            <div style={{ cursor: "pointer" }} onClick={hide}>
              <X size={40} />
            </div>
          ) : (
            <div style={{ width: 30 }}></div>
          )}
        </div>
        <div
          style={{
            maxWidth: 700,
            paddingLeft: 10,
            paddingRight: 10,
            margin: "auto",
          }}
        >
          <div className={styles.statusAndPoolLinkContainer}>
            <StatusBadge
              status={pool_status_badge}
              customStyles={{ padding: 10 }}
              is_bet={is_bet}
            />
            <div style={{ marginLeft: "auto" }}>
              <ViewPoolLink poolId={pool_id} />
            </div>
          </div>
          <BetDetailLine label="Group" value={group.name} />
          <BetDetailLine label="Pool" value={event_name + ": " + pool_name} />
          <BetDetailLine
            label="Event Start"
            value={epochToFormattedDateTime(event_start)}
          />
          <BetDetailLine
            label="Pool Close"
            value={epochToFormattedDateTime(pool_close)}
          />
          <BetDetailLine label="Your Bet" value={contestant_name} />
          <BetDetailLine
            label={
              isSettled
                ? "Final Odds"
                : bet.ww_purchased_amount > 0
                  ? "Original Odds"
                  : "Estimated Odds"
            }
            value={odds.toFixed(2) + "X"}
          />
          <BetDetailLine label="Bet id" value={bet.id} />
          <BetDetailLine
            label="Bet placed at"
            value={epochToFormattedDateTime(created)}
          />
          <BetDetailLine label="Bet Amount" value={formatAsCurrency(amount, group.uri)} />
          {bet.ww_purchased_amount > 0 && (
            <BetDetailLine
              label="Purchased Amount"
              value={formatAsCurrency(bet.ww_purchased_amount, group.uri)}
            />
          )}
          {bet.ww_purchased_amount > 0 && (
            <BetDetailLine
              label="Purchased Odds"
              value={((amount * odds) / ww_purchased_amount).toFixed(2) + "X"}
            />
          )}
          {!isSettled && bet.status != BET_STATUS.SOLD && (
            <div
              style={{ backgroundColor: sparketLightGray }}
              className={styles.projectedPayoutContainer}
            >
              <OpenSansText customStyles={{ fontSize: 10, opacity: 0.5 }}>
                Estimated Payout
              </OpenSansText>
              <BlockyBoldText customStyles={{ fontSize: 20, paddingTop: 5 }}>
                {formattedProjectedPayout}
              </BlockyBoldText>
            </div>
          )}
          {bet.status == BET_STATUS.SOLD && (
            <div
              className={styles.winLossSection}
              style={{
                backgroundColor: "#00965E",
              }}
            >
              <BlockyBoldText className={styles.winLossText}>
                Sold Amount {formatAsCurrency(ww_listed_amount, group.uri)}
              </BlockyBoldText>
            </div>
          )}
          {isSettled && (
            <div
              className={styles.winLossSection}
              style={{
                backgroundColor:
                  status === BET_STATUS.WIN ? "#00965E" : "#CF162E",
              }}
            >
              <BlockyBoldText className={styles.winLossText}>
                {winLossAmount(
                  status,
                  payout,
                  ww_purchased_amount > 0 ? ww_purchased_amount : amount,
                  group.uri
                )}
              </BlockyBoldText>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default BetDetailsContent;
