import { useEffect, useState } from "react";
import { Bet } from "interfaces/bet";
import Header from "components/header";
import { useAppSelector } from "redux/hooks";
import BetDetailsContent from "./bet-details-content";
import KeepBettingButton from "./keep-betting-button";
import { getUserState } from "redux/slices/user-slice";
import { getGanProviderPostfix } from "../../utils/backend-path-builders";
import { getGanProviderPrefix, getGroupState } from "redux/slices/group-slice";

const BetDetailsPage = (props: any) => {
  const [bet, setBet] = useState({} as Bet);
  const [loading, setLoading] = useState(true);
  const user = useAppSelector(getUserState);
  const groupState = useAppSelector(getGroupState);

  useEffect(() => {
    if (props.location.state) {
      setBet(props.location.state.bet);
      setLoading(false);
    } else {
      const id: number = props.match.params.id;
      fetch(
        process.env.REACT_APP_BACKEND_URL +
          `/gan/${getGanProviderPostfix(groupState.uri)}/bets/bet/${id.toString()}`,
        {
          headers: {
            "x-gan-user-id": user.playerId,
            "x-gan-login-token": user.loginToken,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((bet) => {
          setBet(bet);
          setLoading(false);
        });
    }
  }, [props.match.params.id, props.location.state]);

  return (
    <div style={{ backgroundColor: "white" }}>
      <Header />
      <BetDetailsContent bet={bet} loading={loading} />
      <KeepBettingButton />
    </div>
  );
};

export default BetDetailsPage;
