import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { formatAsCurrency } from "utils/formatter-utils/currency-formatter";
import { LeaderboardEntry } from "interfaces/leaderboard/leaderboard-entry";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";

interface Props {
  user: LeaderboardEntry;
  style?: object;
  classNames: any;
}

const UserRow = ({ user, style = {}, classNames }: Props) => {
  const { rank, user_name, total_earnings, user_id } = user;

  const group = useAppSelector(getGroupState);

  return (
    <tr className={classNames.rowContainer} style={style} key={user_id}>
      <td>
        <BlockyHeavyText style={style}>{rank}</BlockyHeavyText>
      </td>
      <td>
        <BlockyHeavyText style={style}>{user_name}</BlockyHeavyText>
      </td>
      <td>
        <BlockyHeavyText style={style}>
          {formatAsCurrency(total_earnings, group.uri)}
        </BlockyHeavyText>
      </td>
    </tr>
  );
};
export default UserRow;
