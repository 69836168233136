import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { Button, Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  getSparketTutorialState,
  close,
} from "redux/slices/sparket-tutorial-slice";
import { sparketGold } from "utils/constants";
import SparketTutorialCarousel from "./carousel";
import sharedModalStyles from "assets/shared-stylesheets/modal.module.css";
import { Mixpanel } from "hooks/mixPanel";
import { useAuth } from "hooks/auth";
import { getGroupState } from "../../redux/slices/group-slice";

const SparketTutorial = () => {
  const show = useAppSelector(getSparketTutorialState);
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const group = useAppSelector(getGroupState);
  const color = group.settings.primary_color;

  return (
    <Modal
      show={show}
      dialogClassName="modal-90w"
      onHide={() => dispatch(close())}
    >
      <Modal.Title
        style={{ justifyContent: "space-between", backgroundColor: color}}
        className={sharedModalStyles.modalTitle}
      >
        <div />
        <BlockyHeavyText
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          Welcome!
        </BlockyHeavyText>
        <Button
          style={{ backgroundColor: color, border: "none" }}
          onClick={() => {
            Mixpanel.track("Closed Tutorial", {
              isSignedIn: auth.signedIn,
            });
            dispatch(close());
          }}
        >
          <BlockyHeavyText customStyles={{ color: "black" }}>X</BlockyHeavyText>
        </Button>
      </Modal.Title>
      <Modal.Body
        className={sharedModalStyles.modalBody}
        style={{ height: 440, borderColor: color }}
      >
        <SparketTutorialCarousel />
      </Modal.Body>
    </Modal>
  );
};

export default SparketTutorial;
