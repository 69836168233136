import Home from "home/home";
import { Route, Switch, useLocation } from "react-router-dom";
import PoolDetails from "components/pool-details";
import BetDetailsPage from "components/bet-details-page";
import MyBets from "components/my-bets";
import Help from "components/help";
import Drawer from "components/drawer";
import "rsuite/dist/styles/rsuite-default.css";
import useDeviceDimensions from "hooks/useDeviceDimensions";
import SideBar from "components/sidebar";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { close } from "redux/slices/drawer-slice";
import SparketTutorial from "components/sparket-tutorial";
import { getGroupState, getGroupStyles } from "redux/slices/group-slice";
import { getUserId, getUserState } from "redux/slices/user-slice";
import ReactGA from "react-ga";
import { useEffect } from "react";
import LegalModal from "components/legal-modal";
import OfflineAlert from "components/offline-alert";
import ConfirmResults from "components/confirm-results";
import ConfirmResultsPoolDetails from "components/confirm-results/pool-details";
import LeaderboardPage from "components/leaderboard-page";
import NotificationSettings from "components/notification-settings";
import { WagerWireIframe, WagerWireProvider } from "@wagerwire/embed/react";
import { APPROVED } from "./utils/constants";

ReactGA.initialize("UA-143614690-1");

const App = () => {
  const excludeNavigationPaths = ["/privacy-policy", "/terms-of-service"];

  const excludeBackgroundColorPaths = [
    "/privacy-policy",
    "/terms-of-service",
    "/help",
    "/leaderboard",
    "/notification-settings",
  ];

  const group = useAppSelector(getGroupState);
  const userId = useAppSelector(getUserId);

  const location = useLocation();
  const showNavigation = !excludeNavigationPaths.includes(location.pathname);
  const showBackgroundColor = !excludeBackgroundColorPaths.includes(
    location.pathname
  );

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);

  const dispatch = useAppDispatch();
  const { isMobile } = useDeviceDimensions();

  useEffect(() => {
    dispatch(close());
  }, [isMobile]);

  const conditionalStyles = isMobile
    ? {}
    : {
        alignSelf: "flex-end",
        height: "100vh",
        overflow: "scroll",
        width: "100%",
      };

  const primaryColor = useAppSelector(getGroupStyles).primary_color;

  const backgoundStyles = showBackgroundColor
    ? {
        backgroundImage: `linear-gradient(to right, ${primaryColor} , black)`,
      }
    : { backgroundColor: "#F1F2F2" };

  const wagerWireEnabled = group.settings.wager_wire;

  return (
    <WagerWireProvider
      userId={userId}
      embedOrigin={process.env.REACT_APP_WAGER_WIRE_EMBED_ORIGIN || ""}
    >
      <div style={{ display: isMobile ? "initial" : "flex" }}>
        {showNavigation && <>{isMobile ? <Drawer /> : <SideBar />}</>}
        <div
          id="sparket-scrollable-container"
          style={{
            minHeight: "100vh",
            ...backgoundStyles,
            ...conditionalStyles,
          }}
        >
          <Switch>
            <Route exact path="/mybets" component={MyBets} />
            <Route exact path="/bets/:id" component={BetDetailsPage} />
            <Route exact path="/pools/:id" component={PoolDetails} />
            <Route exact path="/help" component={Help} />
            <Route exact path="/leaderboard" component={LeaderboardPage} />

            <Route exact path="/" component={Home} />
            <Route exact path="/g/:groupSlug" component={Home} />
            <Route exact path="/confirm-results" component={ConfirmResults} />
            <Route
              exact
              path="/notification-settings"
              component={NotificationSettings}
            />
            <Route
              exact
              path="/pools/:id/confirm"
              component={ConfirmResultsPoolDetails}
            />
            <Route exact path="/launchGame/:groupSlug" component={Home} />
          </Switch>
          <SparketTutorial />
          <LegalModal />
          <OfflineAlert />
        </div>
      </div>
      {wagerWireEnabled && <WagerWireIframe />}
    </WagerWireProvider>
  );
};

export default App;
