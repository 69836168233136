import { formatAsCurrency } from "utils/formatter-utils/currency-formatter";
import { useEffect, useState } from "react";
import { getGanBalancePath } from "utils/backend-path-builders";
// import { getCookie } from "utils/customCookies";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getGroupState, setCurrency } from "redux/slices/group-slice";
import { getUserState, setGanCredentials } from "redux/slices/user-slice";
import { local as localStorage } from "../utils/local-storage";
import { CURRENT_LOGIN_TOKEN, CURRENT_PLAYER_ID } from "../utils/constants";

const useWallet = () => {
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUserState);
  const group = useAppSelector(getGroupState);


  const getWalletData = () => {
    fetch(process.env.REACT_APP_BACKEND_URL + getGanBalancePath(group.uri), {
      headers: {
        "x-gan-user-id": user.playerId,
        "x-gan-login-token": user.loginToken,
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        setBalance(data.total || 0);
        setLoading(false);
        dispatch(setCurrency(data.currency));
        // set new token
        if (data.token !== "") {
          const playerId = user.playerId;
          const loginToken = data.token;
          dispatch(setGanCredentials({ playerId, loginToken }));
          localStorage.setItem(CURRENT_LOGIN_TOKEN, loginToken);
          localStorage.setItem(CURRENT_PLAYER_ID, playerId);
        }
        window.parent.postMessage({ balance: data.total || 0 }, "*");
      });
  };

  useEffect(() => {
    getWalletData();
  }, []);

  const formattedBalance = loading ? "--.--" : formatAsCurrency(balance, group.uri);

  return { balance: balance, formattedBalance };
};

export default useWallet;
