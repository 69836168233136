import { API } from "aws-amplify";

const API_NAME = "api";

export const getRequest = (path: string, options?: any) => {
  const queryParams = options?.queryParams
    ? "?" + new URLSearchParams(options.queryParams)
    : "";
  return fetch(process.env.REACT_APP_BACKEND_URL + path + queryParams)
    .then((res) => res.json())
    .catch((err) => console.log(err));
};

export const postRequest = (path: string, options?: any) => {
  const myInit = {
    body: options?.body,
  };
  return API.post(API_NAME, path, myInit);
};

export const putRequest = (path: string, options?: any) => {
  const myInit = {
    body: options.body,
  };
  return API.put(API_NAME, path, myInit);
};
