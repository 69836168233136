import SocialMediaButtons from "./social-media-buttons";
import { sparketGold } from "utils/constants";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import CopyPoolButton from "./copy-pool-buttton";
import { Bet } from "interfaces/bet";
import { useEffect, useState } from "react";
import { getRequest } from "utils/httpClient";
import { getSocialShareLinksPath } from "utils/backend-path-builders";
import { Spinner } from "react-bootstrap";
import { useAppSelector } from "redux/hooks";
import { getGroupSecondaryColorOrDefault } from "redux/slices/group-slice";

interface Props {
  bet: Bet;
}

const Container = ({ bet }: Props) => {
  const { pool_id, pool_name, contestant_name } = bet;
  const shareUrl = process.env.REACT_APP_SOCIAL_SHARE_URL || "";
  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);

  const [facebookUrl, setFacebookUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [copyUrl, setCopyUrl] = useState("");
  const [redditUrl, setRedditUrl] = useState("");
  const [whatsappUrl, setWhatsappUrl] = useState("");
  const [loadingUrls, setLoadingUrls] = useState(true);
  useEffect(() => {
    getRequest(getSocialShareLinksPath(pool_id), { skipIntegrationApi: true })
        .then((data) => {
            setFacebookUrl(data.facebook || shareUrl);
            setRedditUrl(data.reddit || shareUrl);
            setTwitterUrl(data.twitter || shareUrl);
            setWhatsappUrl(data.whatsapp || shareUrl);
            setCopyUrl(data.link || shareUrl);
            setLoadingUrls(false);
        })
        .catch((err) => {
            setLoadingUrls(false);
        });
    }, [loadingUrls]);

  return (
    <div>
      <BlockyBoldText
        customStyles={{
          color: secondaryColor,
          fontSize: 20,
          textAlign: "center",
          marginLeft: 15,
        }}
      >
        Share with your Betwork!
      </BlockyBoldText>
        {loadingUrls ? (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    color: secondaryColor,
                }}
            >
                <Spinner animation="border" />
            </div>
        ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <CopyPoolButton url={copyUrl} />
                <SocialMediaButtons
                    twitter_url={twitterUrl}
                    facebook_url={facebookUrl}
                    reddit_url={redditUrl}
                    whatsapp_url={whatsappUrl}
                    pool_name={pool_name}
                    contestant_name={contestant_name}
                />
            </div>
        )}
    </div>
  );
};

export default Container;
