import { Drawer } from "rsuite";
import "./drawer-styles.css";
import { ReactComponent as SparketLogo } from "icons/sparket-logo.svg";
import NavigationLinks from "./navigation-links";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { close, getDrawerState } from "redux/slices/drawer-slice";
import Footer from "./footer-content";
import AdBanner from "components/ad-banner/AddBanner";

const SparketDrawer = () => {
  const isOpen = useAppSelector(getDrawerState);
  const dispatch = useAppDispatch();

  return (
    <Drawer
      style={{
        maxWidth: "230px",
      }}
      placement="left"
      backdrop={true}
      full
      show={isOpen}
      onHide={() => {
        dispatch(close());
      }}
      keyboard
    >
      <Drawer.Header>
        <Drawer.Title>
          <SparketLogo width={40} height={40} />
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body style={{ marginLeft: 10 }}>
        <NavigationLinks />
        <AdBanner />
      </Drawer.Body>
      <Drawer.Footer style={{ marginLeft: 10 }}>
        <Footer />
      </Drawer.Footer>
    </Drawer>
  );
};
export default SparketDrawer;
