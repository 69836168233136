import { Nav } from "react-bootstrap";
import { sparketBlack } from "utils/constants";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { useState } from "react";
import { BetTypeName } from "interfaces/bet-type-name";
import { useAppSelector } from "../../../../redux/hooks";
import { getGroupSecondaryColorOrDefault } from "../../../../redux/slices/group-slice";

interface BetTabsProps {
  orderedBetTypes: BetTypeName[];
}

const BetTabs = ({orderedBetTypes}: BetTabsProps) => {
  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);
  const [activeBetTypeTab, setActiveBetTypeTab] = useState<string>(orderedBetTypes[0]);

  return (
    <Nav
      justify
      variant="pills"
      style={{
        paddingTop: 10,
        paddingBottom: 5,
        paddingLeft: 20,
        paddingRight: 20,
      }}
      activeKey={activeBetTypeTab}
      onSelect={(selectedTab) => selectedTab && setActiveBetTypeTab(selectedTab)}
    >
      {orderedBetTypes.length > 1 &&
        orderedBetTypes.map((type) => {
          const active = type === activeBetTypeTab;
          console.log(`render type ${type}, active: ${active}`);
          return (
            <Nav.Item key={type} style={{ paddingRight: 5 }}>
              <Nav.Link eventKey={type} style={{
                textTransform: "capitalize",
                cursor: "pointer",
                color: active ? sparketBlack : "white",
                backgroundColor: active ? `${secondaryColor}` : "grey"
              }}>
                <BlockyBoldText>{type}</BlockyBoldText>
              </Nav.Link>
            </Nav.Item>
          );
        })}
    </Nav>
  )
}


export default BetTabs;