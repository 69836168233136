import { configureStore } from "@reduxjs/toolkit";
import drawerReducer from "./slices/drawer-slice";
import sparketTutorialReducer from "./slices/sparket-tutorial-slice";
import groupReducer from "./slices/group-slice";
import legalModalReducer from "./slices/legal-modal-slice";
import userSlice from "./slices/user-slice";
import userSettingsSlice from "redux/slices/user-settings-slice";

const store = configureStore({
  reducer: {
    drawer: drawerReducer,
    legalModal: legalModalReducer,
    sparketTutorial: sparketTutorialReducer,
    userSettings: userSettingsSlice,
    group: groupReducer,
    user: userSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
