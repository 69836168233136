import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import styles from "./header-styles.module.scss";
import { toggle as toggleDrawerState } from "redux/slices/drawer-slice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import useDeviceDimensions from "hooks/useDeviceDimensions";
import { getGroupState, getGroupStyles } from "redux/slices/group-slice";
import { List } from "react-bootstrap-icons";

const Header = () => {
  const dispatch = useAppDispatch();
  const groupName = useAppSelector(getGroupState).name;
  const { isMobile } = useDeviceDimensions();
  const groupSettings = useAppSelector(getGroupStyles);

  return (
    <div style={{ backgroundColor: "black" }} className={styles.container}>
      <div className={styles.logoContainer}>
        <div
          onClick={() => {
            dispatch(toggleDrawerState());
          }}
        >
          {isMobile && (
            <List style={{ color: "white", fontSize: 25, marginTop: 5 }} />
          )}
        </div>
      </div>
      <BlockyBoldText className={styles.groupNameText}>
        {groupSettings.logo_url ? (
          <img src={groupSettings.logo_url} height={52} />
        ) : (
          groupName
        )}{" "}
      </BlockyBoldText>
      <div></div>
    </div>
  );
};

export default Header;
