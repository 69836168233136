import {
  PoolDetailsResponse,
  ResultsResponse,
} from "interfaces/pool-details-response";
import { Table } from "react-bootstrap";
import { Entry } from "interfaces/entry";
import epochToFormattedDateTime from "utils/formatter-utils/date-formatter";
import { ContestantResponse } from "interfaces/contestant-response";

interface Props {
  pool: PoolDetailsResponse;
}

const buildWinner = (result: ResultsResponse, contestants: ContestantResponse[]): string => {
  const places = result.pool_winner_places;
  let resultString = ``;

  for (const place in places) {
    const contestantIds = places[place];
    const contestantNames = contestantIds.map(id => {
      const contestant = contestants.find(c => c.id === id);
      return contestant ? contestant.name : "Unknown";
    }).join(", ");

    resultString += `${place}: ${contestantNames}\n`;
  }

  return resultString;
};

const OutcomeHistoryTable = ({ pool }: Props) => {
  return (
    <Table style={{ color: "white" }}>
      <thead>
        <tr>
          <th>Winner</th>
          <th>Outcome</th>
          <th>Changed at</th>
        </tr>
      </thead>
      <tbody>
      {pool?.results?.result_changes.map((resultChange: ResultsResponse) =>
        <tr key={resultChange.id}>
          <td>{buildWinner(resultChange, pool.contestants)}</td>
          <td>{resultChange.description}</td>
          <td>
            {epochToFormattedDateTime(
              new Date(resultChange.created_at).getTime()
            )}
          </td>
        </tr>)}
      </tbody>
    </Table>
  );
};

export default OutcomeHistoryTable;
