import sparketTutorial3 from "assets/sparket-tutorial/sparket-tutorial-3.png";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import BlockyBlockyMediumText from "components/custom-texts/blocky-medium-text";
import styles from "./styles.module.css";

const PageOneContent = () => {
  return (
    <div className={styles.pageContentContainer}>
      <BlockyBlockyMediumText className={styles.topText}>
        Now, let’s say a <span className={styles.bold}>new user </span> bets{" "}
        <span className={styles.bold}>100 </span> on{" "}
        <span className={styles.bold}>GT </span>to bring his total to{" "}
        <span className={styles.bold}>200 </span>and the pool total to{" "}
        <span className={styles.bold}>400 </span>.
      </BlockyBlockyMediumText>
      <img width="90%" src={sparketTutorial3} alt="First slide" />
      <BlockyBlockyMediumText className={styles.bottomText}>
        <span className={styles.bold}>GT odds would lower</span> and{" "}
        <span className={styles.bold}>BT odds would rise</span> to 2x (400
        / 200 minus any commission)
      </BlockyBlockyMediumText>
      <BlockyMediumText style={{ fontSize: 12 }}>
        (Minus any commision)
      </BlockyMediumText>
    </div>
  );
};

export default PageOneContent;
