import { createSlice } from "@reduxjs/toolkit";
import UserGroupsResponse from "interfaces/user-groups-response";
// import { getJoinGroupPath } from "utils/backend-path-builders";
import { CURRENT_GROUP_ID, IDLE, sparketGold, } from "utils/constants";
import { local as localStorage } from "utils/local-storage";
import { RootState } from "../store";

const SECTION_SIGN = "§";
export const DOLLAR_SIGN = "$";

export type GroupUri = "sparketsportsbook" | "pennplaylive" | ""

export interface GroupState extends UserGroupsResponse {
  status: string;
  fetchStatusState: "idle" | "loading" | "succeeded" | "failed";
  homePath: string;
}

const INITIAL_PRIMARY_COLOR = "white";

export const initialState: GroupState = {
  updated_at: "",
  created_at: "",
  id: "",
  name: "",
  fee: 0,
  settings: {
    primary_color: INITIAL_PRIMARY_COLOR,
    logo_url: "",
    background_skin: "",
    external_id_label: "external id label",
    contest_terms_url: "",
    group_owner: "",
    contest_label: "",
    wager_wire: false,
  },
  currency_code: "skc",
  status: "",
  fetchStatusState: IDLE,
  uri: "",
  homePath: "",
};

// export const requestToJoinGroup = createAsyncThunk(
//   "group/joinRequest",
//   async (groupId: string, thunkApi) => {
//     const path = getJoinGroupPath(groupId);
//     await postRequest(path);
//     thunkApi.dispatch(fetchGroupStatus(groupId));
//   }
// );

// export const fetchGroupStatus = createAsyncThunk(
//   "group/fetchStatus",
//   async (groupId: string, _) => {
//     const path = getJoinGroupPath(groupId);
//     const response = await getRequest(path, {
//       responseType: "text",
//     });
//     return response;
//   }
// );

const slice = createSlice({
  name: "group",
  initialState,
  reducers: {
    setGroupStatus: (state, action) => {
      state.status = action.payload;
    },
    setHomePath: (state, action) => {
      state.homePath = action.payload;
    },
    removeGroupSettings: (state) => {
      state.settings = initialState.settings;
    },
    setCurrency: (state, action) => {
      state.currency_code = action.payload;
    },
    setGroup: (state, action) => {
      if (!action.payload) {
        return state;
      }
      localStorage.setItem(CURRENT_GROUP_ID, action.payload.id);
      state.id = action.payload.id;
      state.updated_at = action.payload.updated_at;
      state.created_at = action.payload.created_at;
      state.name = action.payload.name;
      state.fee = action.payload.fee;
      state.settings = action.payload.settings;
      state.uri = action.payload.uri;
      state.currency_code = action.payload.currency_code;
    },
  },
  // extraReducers(builder) {
  //   builder.addCase(fetchGroupStatus.pending, (state, action) => {
  //     state.fetchStatusState = LOADING;
  //   });
  //   builder.addCase(fetchGroupStatus.fulfilled, (state, action) => {
  //     state.status = action.payload;
  //     state.fetchStatusState = SUCCEEDED;
  //   });
  //   builder.addCase(fetchGroupStatus.rejected, (state, action) => {
  //     state.fetchStatusState = FAILED;
  //   });
  // },
});

export const {
  setGroup,
  removeGroupSettings,
  setGroupStatus,
  setHomePath,
  setCurrency,
} = slice.actions;

export const getGroupState = (state: RootState) => {
  return state.group;
};

export const getGroupStyles = (state: RootState) => {
  return getGroupState(state).settings;
};

export const getGroupSecondaryColorOrDefault = (state: RootState) => {
  return getGroupState(state).settings.secondary_color || sparketGold;
};

export const getGroupStatus = (state: RootState) => {
  return getGroupState(state).status;
};

export const getGroupCurrencySymbol = (state: RootState) => {
  // const backendCurrencyCode = getGroupState(state).currency_code;

  // if (backendCurrencyCode === "usd") {
  //   return DOLLAR_SIGN;
  // }

  // return SECTION_SIGN;
  return "";
};

export const isRealMoneyGroup = (state: RootState) => {
  return getGroupCurrencySymbol(state) === DOLLAR_SIGN;
};

export const getSlugPath = (state: RootState) => {
  const uri = getGroupState(state).uri;
  return "/g/" + uri;
};

export const getSlugUrl = (state: RootState) => {
  const baseUrl = process.env.REACT_APP_HOST_URL;
  const uri = getGroupState(state).uri;
  return baseUrl + "g/" + uri;
};

export const getGanProviderPrefix = (state: RootState) => {
  const uri = getGroupState(state).uri;
  if (uri === "sparketsportsbook") {
    return "gan";
  } else if (uri === "pennplaylive") {
    return "penn";
  } else {
    throw new Error("failed to resolve gan provider prefix from uri: " + uri);
  }
};
export default slice.reducer;
