import { formattedCurrencyToNumber } from "utils/formatter-utils/currency-formatter";
import { Dispatch, SetStateAction } from "react";
import { Bet } from "interfaces/bet";
import { getGanProviderPostfix } from "../../utils/backend-path-builders";
import { GroupUri } from "redux/slices/group-slice";

const POLLING_FREQUENCY = 200;
const WAIT_TIME_BEFORE_POLLING = 1000;
const MAX_RETRIES = 30;

const pollForBetDetails = (
  betId: string,
  groupUri: string,
  onSuccessfulBetPlaced: any,
  setLoading: any,
  setErrorMessage: any,
  retryNumber: number,
  playerId: string,
  loginToken: string
) =>
  fetch(process.env.REACT_APP_BACKEND_URL + `/gan/${getGanProviderPostfix(groupUri)}/bets/bet/${betId}`, {
    headers: {
      "x-gan-user-id": playerId,
      "x-gan-login-token": loginToken,
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.error || data.technical_error) {
        if (retryNumber >= MAX_RETRIES) {
          setErrorMessage(data.friendly_error_message);
          setLoading(false);
        } else {
          setTimeout(
            () =>
              pollForBetDetails(
                betId,
                groupUri,
                onSuccessfulBetPlaced,
                setLoading,
                setErrorMessage,
                retryNumber + 1,
                playerId,
                loginToken
              ),
            POLLING_FREQUENCY
          );
        }
      } else {
        onSuccessfulBetPlaced(data);
      }
    })
    .catch((e) => {
    });

interface ConfirmBetArguments {
  betAmount: string;
  pool_id: string;
  groupUri: GroupUri;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>;
  poolEntryId: string;
  onSuccessfulBetPlaced: (bet: Bet) => void;
  currency: string;
  playerId: string;
  loginToken: string;
}

const confirmBet = ({
                      betAmount,
                      poolEntryId,
                      pool_id,
                      groupUri,
                      setLoading,
                      setErrorMessage,
                      onSuccessfulBetPlaced,
                      currency,
                      playerId,
                      loginToken,
                    }: ConfirmBetArguments) => {
  setLoading(true);

  const bet_amount = formattedCurrencyToNumber(betAmount);
  setErrorMessage("");

  // POST: {BASE_URL}/gan/{POOL_ID}/entries/entry/{ENTRY_ID}/bet
  const path =
    process.env.REACT_APP_BACKEND_URL +
    `/gan/${getGanProviderPostfix(groupUri)}/${pool_id}/entries/entry/${poolEntryId}/bet`;

  fetch(path, {
    method: "POST",
    headers: {
      "x-gan-user-id": playerId,
      "x-gan-login-token": loginToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      bet_amount,
      currency,
    }),
  })
    .then((data) => data.json())
    .then((data) => {
      if (data.error === "Cannot place bet") {
        setErrorMessage(
          "Failed to place bet. Please try to refresh the page or contact support"
        );
        setLoading(false);
        return;
      }
      setTimeout(
        () =>
          pollForBetDetails(
            data.bet_id,
            groupUri,
            onSuccessfulBetPlaced,
            setLoading,
            setErrorMessage,
            0,
            playerId,
            loginToken
          ),
        WAIT_TIME_BEFORE_POLLING
      );
    })
    .catch((e) => {
      setLoading(false);
      setErrorMessage("Failed to place bet.");
    });
};
export default confirmBet;
