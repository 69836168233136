import { getCookie } from "utils/customCookies";

export const authConfig = {
  API: {
    endpoints: [
      {
        name: "api",
        endpoint: process.env.REACT_APP_BACKEND_URL,
        custom_header: async () => {
          try {
            const identityCookie = getCookie("gan_player_info");
            return {
              "x-client-id": "0",
              "x-gan-user-id": identityCookie.playerId,
              "x-gan-login-token": identityCookie.loginToken,
            };
          } catch (e) {
            return {};
          }
        },
      },
    ],
  },
};
