// initialize game path
import { GroupUri } from "redux/slices/group-slice";

export const getGanProviderPostfix = (groupUri: string) => {
  if (groupUri === "sparketsportsbook") {
    return "gan";
  } else if (groupUri === "pennplaylive") {
    return "penn";
  } else {
    throw new Error("failed to resolve gan provider prefix from uri: " + groupUri);
  }
};

export const getInitializeGamePath = (groupUri: string) => `/gan/${getGanProviderPostfix(groupUri)}/initialize-game`;
// bet list page
export const getMyBetsPath = (groupUri: string) => `/gan/${getGanProviderPostfix(groupUri)}/bets`;

// v2/groups
const GROUPS_LAMBDA_PREFIX = "/v2/groups";
export const getPoolDetailsPath = (groupId: string, poolId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}}/pools/pool/${poolId}`;

export const getGroupDetailsPath = (groupId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}`;

export const getGroupBySlugPath = (slug: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/slug/${slug}`;

export const getPoolsPath = (groupId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}/pools`;

// export const getBetDetailsPath = (betId: string) => `/gan/bets/bet/${betId}`;

export const getContestsPath = (groupId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}/contests`;

export const getSocialShareLinksPath = (poolID: string) =>
  `/v2/pools/pool/${poolID}/socialshare`;

export const getConfirmBetPath = (
  groupId: string,
  poolId: string,
  poolEntryId: string
) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}/pools/pool/${poolId}/entries/entry/${poolEntryId}/bet`;

// export const getJoinGroupPath = (groupId: string) =>
//   `${GROUPS_LAMBDA_PREFIX}/join/${groupId}`;

// v2/pmSys
export const getConfirmedContestantPath = (poolId: string, groupUri: GroupUri) => `/gan/${getGanProviderPostfix(groupUri)}/confirmation/pool/${poolId}/selected`;

export const getConfirmContestantPath = (groupUri: GroupUri) => `/gan/${getGanProviderPostfix(groupUri)}/confirmation/record`;

export const getGanBalancePath = (groupUri: GroupUri) => `/gan/${getGanProviderPostfix(groupUri)}/balance`;

export const getEntryPath = (groupId: string, poolId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}/pools/pool/${poolId}/entries/entry`;

// v2/wallet
const WALLET_LAMBDA_PREFIX = "/v2/wallet";
export const getWalletPath = (groupId: string) =>
  `${WALLET_LAMBDA_PREFIX}/user/group/${groupId}`;

// v2/transactions
const TRANSACTIONS_LAMBDA_PREFIX = "/v2/transactions";
export const getTransactionsForWalletPath = (walletId: string) =>
  `${TRANSACTIONS_LAMBDA_PREFIX}/wallet/${walletId}`;

export const getLeaderboardDataPath = () =>
  `${TRANSACTIONS_LAMBDA_PREFIX}/leader-board`;

// v2/users
const USERS_LAMBDA_PREFIX = "/v2/users";
export const getUserPath = (groupUri: GroupUri) => `/gan/${getGanProviderPostfix(groupUri)}/users/me`;

export const getUserPathNotifications = (groupUri: GroupUri) => `/gan/${getGanProviderPostfix(groupUri)}/users/me?notifications=1`;

// export const getUsersPersonalInformationPath = () =>
//   `${USERS_LAMBDA_PREFIX}/verification/personal-information`;

export const getUsersExternalIdPath = () =>
  `${USERS_LAMBDA_PREFIX}/external-id`;

export const getUsersExternalIdsPath = () =>
  `${USERS_LAMBDA_PREFIX}/external-ids`;

export const getSubmitAvaterPath = () =>
  `${USERS_LAMBDA_PREFIX}/profile/avatar`;

export const getValidatePotentialUsernamePath = () =>
  `${USERS_LAMBDA_PREFIX}/onboarding/username`;

export const getVerificationRequestPath = (userId: string) =>
  `${USERS_LAMBDA_PREFIX}/user/${userId}/verification/idscan/sms`;

export const getFailedLoginPath = () =>
  `${USERS_LAMBDA_PREFIX}/user/events/event/login-failed`;

// v2/notification-configurations
const CONFIGURABLE_NOTIFICACTIONS_LAMBDA_PREFIX =
  "/v2/notification-configurations";
export const getConfigurableNotificationsPath = () =>
  CONFIGURABLE_NOTIFICACTIONS_LAMBDA_PREFIX;

// v2/legal
const LEGAL_LAMBDA_PREFIX = "/v2/legal";
export const getPrivacyPolicyPath = () =>
  `${LEGAL_LAMBDA_PREFIX}/docs/privacy-policy/v1`;

export const getTermsOfServicePath = () =>
  `${LEGAL_LAMBDA_PREFIX}/docs/terms-of-service/v1`;

const EVENTS_LAMBDA_PREFIX = "/v2/events";
export const getEventsPath = () => EVENTS_LAMBDA_PREFIX;

// v2/limitations
const LIMITATIONS_LAMBDA_PREFIX = "/v2/limitations";
export const getSelfLimitationsPath = () => `${LIMITATIONS_LAMBDA_PREFIX}/self`;
export const getSelfExclusionPath = () =>
  `${LIMITATIONS_LAMBDA_PREFIX}/self/exclusion`;
