import OpenSansText from "components/custom-texts/open-sans-text";

export const LineBreak = () => {
  return <hr style={{ margin: 5 }} />;
};

const CONTENT = [
  "Just because a pool is closed, that doesn’t mean the action stops! Powered by WagerWire, bets can continue to be bought or sold within the Live Marketplace up until the pool is completely settled. This feature may only be available for select groups and events.",
  "Click the ‘Live Markets’ button at the top of the home page to open the bet trading marketplace window and browse the available bets.",
  "All the bets within the marketplace were listed by fellow users. The price and odds of each bet is determined by the seller.",
  "Selecting a bet from the marketplace will bring you to the bet detail page. There you will see all the info related to the bet, the pool, and the event. You can select the ‘More Details’ button to expand the view and see more information about the bet, or ‘Hide Details’ to contract those details. If this is a bet that you Listed, you will be able to update or remove it. You can also Press the back button to return to the Live Market page.",
  "Click ‘Buy Bet’ to purchase the wager at the listed price. This will use your same funds as if it was a regularly placed wager for an open pool. Bets purchased from the marketplace will then show on your My Bets page along with your other bets.",
  "After listing a bet for sale, you will receive a ‘Bet Listed!’ confirmation. From here you can select ‘View Marketplace’ to see all Active listings, or ‘My Bets’ to return to the My Bets page.",
  "You can search for any bet name, market, or activity in the search form. Just type in a search term and hit enter to filter any of the available bets for purchase.",
  "At any time you can exit the Live Markets page by selecting the ‘X’ at the top right or clicking outside of the window.",
];

const LiveMarkets = () => {
  return (
    <>
      {CONTENT.map((string, index) => {
        const isLast = index === CONTENT.length - 1;
        return (
          <div style={{ paddingLeft: 10, paddingRight: 10 }} key={index}>
            <OpenSansText>{string}</OpenSansText>
            {!isLast && <LineBreak />}
          </div>
        );
      })}
    </>
  );
};

export default LiveMarkets;
