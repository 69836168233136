import { betTypeWin, BetTypeName } from "interfaces/bet-type-name";
import { Tab } from "react-bootstrap";
import SingleSelectionEntryList from "components/pool-details/entries/single-selection-entry-list/single-selection-entry-list";
import OrderedSelectionEntryList from "components/pool-details/entries/ordered-selection-entry-list/ordered-selection-entry-list";
import { PoolDetailsResponse } from "interfaces/pool-details-response";
import { Entry } from "interfaces/entry";
import { useAuth } from "hooks/auth";
import { useCallback } from "react";


interface BetEntryProps {
  type: BetTypeName;
  pool: PoolDetailsResponse;
  setIsFetchingEntry: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEntry?: Entry;
  setSelectedEntry: React.Dispatch<React.SetStateAction<Entry | undefined>>;
}

const BetEntry = ({
                    type,
                    pool,
                    setIsFetchingEntry,
                    selectedEntry,
                    setSelectedEntry,
}: BetEntryProps) => {

  const auth = useAuth();
  const selectBet = useCallback(
    (entry: Entry) => {
      if (!entry) {
        // User unselected a contestant, hide the form
        setSelectedEntry(undefined);
        return;
      }
      setSelectedEntry(entry);
      setIsFetchingEntry(false);
    },
    [setSelectedEntry, setIsFetchingEntry]
  );

  const handleSetIsFetchingEntry = useCallback(() => {
    setIsFetchingEntry(auth.signedIn);
  }, [setIsFetchingEntry, auth.signedIn]);


  const renderSingleEntryList = () => {
    return (
      <SingleSelectionEntryList
        contestants={pool.contestants}
        entries={pool.entries}
        selectBet={selectBet}
        winningContestantIds={pool.winner_contestant_ids_places ? (pool.winner_contestant_ids_places[1] || []) : []}
        poolStatus={pool.status}
        selectedEntry={selectedEntry}
        setIsFetchingEntry={setIsFetchingEntry}
      />
    )
  };

  const renderMultipleEntryList = () => {
    return (
      <OrderedSelectionEntryList
        contestants={pool.contestants}
        selectBet={selectBet}
        poolType={type}
        poolId={pool.id}
        pool={pool}
        setIsFetchingEntry={handleSetIsFetchingEntry}
      />
    )
  };

  const entryList = type === betTypeWin ? renderSingleEntryList() : renderMultipleEntryList();


  return (
    <Tab.Pane key={type} eventKey={type}>
      {entryList}
    </Tab.Pane>
  );
};

export default BetEntry;