import OpenSansText from "components/custom-texts/open-sans-text";
import { LineBreak } from "./highlights";
import darkStyles from "../dark-styles.module.css";

import Accordion from "react-bootstrap/Accordion";
import AccordionHeader from "../accordion/accordion-header";
import { sparketBlack } from "utils/constants";
import { useAppSelector } from "redux/hooks";
import { getGroupSecondaryColorOrDefault } from "redux/slices/group-slice";

const CONTENT = [
  {
    title: "Pool Wagering",
    body: [
      "Pool wagering (pari-mutuel) betting is a betting style popularized by horse racing in which the odds for each contestant are set based on the volume of wagers on each contestant compared to the entire wagering pool. For example, if a wagering pool has $100 in Active Bets, and a specific contestant has $10 in Active Bets, the contestant’s odds will be $100/$10 = 10x. See Decimal Odds.",
      "Odds update based on actual wager volumes and are not finalized until all wagers have been accepted and the pool has closed. This means that your wager amount directly affects the odds in the pool, and future wagers after you place your wager will also impact your odds.",
    ],
  },
  {
    title: "Pool Status",
    body: [
      "Wagering Pools have various statuses displayed in the user interface which indicate which stage the pool is in:",
      "Open: this pool is currently open and accepting bets.",
      "In Progress: The pool has closed to accepting new bets and is waiting for results to be reporting (e.g. the event is happening). You may be able to sell this bet via the Sell Bet Button on the ‘My Bets Page’ (Only available for some groups and event types)",
      "Settled: the winning contestant in a pool has been reported and winners have been paid out accordingly.",
      "The leaderboard calculation is based on your profit across pools that have been settled or bets that have been sold. It calculates the amount you won minus the amount you bet for standard bets, and will factor in the amount you bought or sold a bet for if applicable",
    ],
  },
  {
    title: "Active Bets",
    body: [
      "The total amount of bets currently accepted on a specific pool or contestant.",
    ],
  },
  {
    title: "Current Odds",
    body: [
      "The Current Odds on a contestant based on the volumes in an open pari-mutuel pool. These odds are subject to change prior to the pool closing.",
    ],
  },
  {
    title: "Decimal Odds",
    body: [
      "Odds are indicated as Decimal, meaning the return received from $1 multiplied by the fraction. For example a $1 wager on a 10x Odds would yield a total payout of $10 (profit of $9 and original wager of $1).",
    ],
  },
  {
    title: "Estimated Winnings",
    body: [
      "The Estimated Payout of a pari-mutuel pool. Note that this amount may change if the pool is still Open. See Current Odds.",
    ],
  },

  {
    title: "Commission",
    body: [
      "The percentage of winnings that an operator and/or regulator takes out of a pool. This amount is automatically reflected in Odds and Estimating Winnings.",
    ],
  },

  {
    title: "The Social Betwork",
    body: [
      "Your social community of sports and esports fans who share pools with one another.",
    ],
  },
];

const BodyContent = ({ content }: any) => {
  return (
    <>
      {content.map((paragraph: string, index: number) => {
        const isLast = index === content.length - 1;
        return (
          <div style={{ paddingLeft: 10, paddingRight: 10 }} key={index}>
            <OpenSansText>{paragraph}</OpenSansText>
            {!isLast && <LineBreak />}
          </div>
        );
      })}
    </>
  );
};

const WageringTerminology = () => {
  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);

  return (
    <>
      <Accordion>
        {CONTENT.map((page, index) => {
          return (
            <div key={index}>
              <AccordionHeader
                eventKey={index.toString()}
                styles={darkStyles}
                backgroundColor={sparketBlack}
                color={secondaryColor}
              >
                {page.title}
              </AccordionHeader>
              <Accordion.Collapse eventKey={index.toString()}>
                <BodyContent content={page.body} />
              </Accordion.Collapse>
            </div>
          );
        })}
      </Accordion>
    </>
  );
};

export default WageringTerminology;
