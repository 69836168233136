import OpenSansText from "components/custom-texts/open-sans-text";

export const LineBreak = () => {
  return <hr style={{ margin: 5 }} />;
};

const CONTENT = [
  "A bet whose status is ‘In Progress’, can be sold on the live marketplace for select events. Do so by clicking the ‘Sell Bet’ button that appears on eligible bets on the ‘My Bets’ page.",
  "Once you click ‘Sell Bet’ you can select how much you wish to list it for on the marketplace. You can choose any price you like that is positive and less than the payout of the wager by entering a number or using the up and down arrow buttons next to the number. Click “List Bet for Sale” to post it to the marketplace.",
  "After listing a bet for sale, you will receive a ‘Bet Listed!’ confirmation. From here you can select ‘View Marketplace’ to see all Active listings, or ‘My Bets’ to return to the My Bets page.",
  "After Listing a bet, the ‘Sell Bet’ button on the ‘My Bets’ page becomes a ‘Listed’ button. Clicking that button allows you to change the price of your sales listing or remove it from the marketplace altogether. To do so, select the ‘Edit Listing’ button.",
  "Once you Select Edit Listing, you can select the ‘Remove Listing’ button to remove the listing from the Marketplace, or you can edit the Amount (in the same way as initially selling it), and then select the ‘Update Listing’ button to change the price.",
  "If someone purchases your bet, you will be notified of the sale and receive the sales price in your wallet. You can track the history of all sold bets within the ‘Bankroll Page’ and ‘My Bets’ page.",
  "At any time you can exit the My Bets page by selected the ‘X’ at the top right or clicking outside of the window.",
];

const SellBet = () => {
  return (
    <>
      {CONTENT.map((string, index) => {
        const isLast = index === CONTENT.length - 1;
        return (
          <div style={{ paddingLeft: 10, paddingRight: 10 }} key={index}>
            <OpenSansText>{string}</OpenSansText>
            {!isLast && <LineBreak />}
          </div>
        );
      })}
    </>
  );
};

export default SellBet;
