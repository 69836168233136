import { Bet } from "interfaces/bet";
import ShareButtons from "components/bet-details-page/share-buttons";
import { Link } from "react-router-dom";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";

interface Props {
  bet: Bet;
}

const Content = ({ bet }: Props) => {
  return (
    <>
      <BlockyMediumText
        style={{ color: "white", display: "flex", justifyContent: "center" }}
      >
        Click&nbsp;<Link to={`/bets/${bet.id}`}>here</Link>&nbsp;to view details
        about your bet.
      </BlockyMediumText>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ShareButtons bet={bet} />
      </div>
    </>
  );
};

export default Content;
