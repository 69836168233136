import useDeviceDimensions from "hooks/useDeviceDimensions";
import { EventsResponse } from "interfaces/events-response";
import { Dispatch, SetStateAction, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import { getEventsPath } from "utils/backend-path-builders";
import { getRequest } from "utils/httpClient";

interface Props {
  setEvent: Dispatch<SetStateAction<any>>;
}
const EventFilter = ({ setEvent }: Props) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const { isMobile } = useDeviceDimensions();
  const eventFilterWidth = isMobile ? "100%" : "50%";
  const groupId = useAppSelector(getGroupState).id;

  const fetchOptions = () => {
    setLoading(true);
    getRequest(getEventsPath(), { queryParams: { "group-id": groupId } }).then(
      (data) => {
        const options = data
          .filter((event: EventsResponse) => !event.is_deleted)
          .map((event: EventsResponse) => {
            return { id: event.id, label: event.name };
          });
        setOptions(options);
        setLoading(false);
      }
    );
  };

  return (
    <div style={{ width: eventFilterWidth, paddingRight: 5, paddingBottom: 5 }}>
      <Typeahead
        id="leaderboard-event-filter"
        options={options}
        placeholder="Event Name"
        onFocus={() => {
          if (options.length === 0 && !loading) {
            fetchOptions();
          }
        }}
        clearButton
        onChange={(filter) => {
          setEvent(filter[0]);
        }}
        isLoading={loading}
      />
    </div>
  );
};

export default EventFilter;
