import sparketTutorial2 from "assets/sparket-tutorial/sparket-tutorial-2.png";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import BlockyBlockyMediumText from "components/custom-texts/blocky-medium-text";
import styles from "./styles.module.css";

const PageOneContent = () => {
  return (
    <div className={styles.pageContentContainer}>
      <BlockyHeavyText className={styles.topText}>
        Let’s say the Green Team and the Blue Team are in a matchup as
        contestants in a pool.
      </BlockyHeavyText>
      <img width="90%" src={sparketTutorial2} alt="First slide" />
      <BlockyBlockyMediumText className={styles.bottomText}>
        Users bet a total <span className={styles.bold}>100 </span> on{" "}
        <span className={styles.bold}>Green Team [GT]</span> and{" "}
        <span className={styles.bold}> 200 </span>on{" "}
        <span className={styles.bold}> Blue Team [BT]</span> for a pool total of
        <span className={styles.bold}> 300</span>.{" "}
        <span className={styles.bold}> GT </span> odds would be{" "}
        <span className={styles.bold}> 3x </span> (300/100), and{" "}
        <span className={styles.bold}> BT </span> odds would be{" "}
        <span className={styles.bold}> 1.5x </span> (300/200)
      </BlockyBlockyMediumText>
    </div>
  );
};

export default PageOneContent;
