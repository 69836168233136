import sparketTutorial4 from "assets/sparket-tutorial/sparket-tutorial-4.png";
import BlockyBlockyMediumText from "components/custom-texts/blocky-medium-text";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import styles from "./styles.module.css";
import { close } from "redux/slices/sparket-tutorial-slice";
import { Button } from "react-bootstrap";
import { sparketBlack } from "utils/constants";
import { getGroupSecondaryColorOrDefault } from "redux/slices/group-slice";

const PageOneContent = () => {
  const dispatch = useAppDispatch();
  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);

  return (
    <div className={styles.pageContentContainer}>
      <BlockyBlockyMediumText className={styles.topText}>
        The odds on the pools are{" "}
        <span className={styles.bold}>not fixed until the closing time</span>,
        which is always listed on each pool before a wager is placed.
      </BlockyBlockyMediumText>
      <img width="90%" src={sparketTutorial4} alt="First slide" />
      <BlockyBlockyMediumText className={styles.bottomText}>
        For more detailed information, please see the{" "}
        <Link
          onClick={() => dispatch(close())}
          style={{ color: "black" }}
          className={styles.bold}
          to="/help"
        >
          Help section
        </Link>
        . We always appreciate any feedback. This Online Casino website is a free-to-play website with no real money online
        gaming supported.
      </BlockyBlockyMediumText>
      <Button
        onClick={() => dispatch(close())}
        style={{
          backgroundColor: sparketBlack,
          border: "none",
          color: secondaryColor,
        }}
      >
        Got it!
      </Button>
    </div>
  );
};

export default PageOneContent;
