import Header from "components/header";
import LoadingSpinner from "components/loading-spinner.tsx";
import PoolSummary from "components/pool-details/pool-summary";
import { Entry } from "interfaces/entry";
import { PoolDetailsResponse } from "interfaces/pool-details-response";
import { useEffect, useState } from "react";
import Headroom from "react-headroom";
import { getRequest } from "utils/httpClient";
import Representer from "./representer";
import { Alert } from "react-bootstrap";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import { getConfirmedContestantPath, getPoolDetailsPath, } from "utils/backend-path-builders";
import { getUserState } from "redux/slices/user-slice";

interface Props {
  match: { params: { id: number } };
}

const Container = (props: Props) => {
  const [loadingPool, setLoadingPool] = useState(true);
  const [entries, setEntries] = useState<Entry[]>([]);
  const [pool, setPool] = useState<PoolDetailsResponse>(
    {} as PoolDetailsResponse
  );
  const group = useAppSelector(getGroupState);
  const [confirmedContestantId, setConfirmedContestantId] = useState("");
  const user = useAppSelector(getUserState);

  useEffect(() => {
    if (pool.id) {
      const path = getConfirmedContestantPath(pool.id, group.uri);
      fetch(process.env.REACT_APP_BACKEND_URL + path, {
        method: "GET",
        headers: {
          "x-gan-user-id": user.playerId,
          "x-gan-login-token": user.loginToken,
          "Content-Type": "application/json",
        },
      })
        .then((data) => data.json())
        .then((data) => {
          if (data && data.contestant_id) {
            setConfirmedContestantId(data.contestant_id);
          }
        });
    }
  }, [pool]);

  useEffect(() => {
    if (!group.id) {
      return;
    }
    const id: number = props.match.params.id;
    const path = getPoolDetailsPath(group.id, id.toString());
    getRequest(path).then((data) => {
      setEntries(data.entries);
      setPool(data);
      setLoadingPool(false);
    });
  }, [props.match.params.id, group.id]);

  if (loadingPool) {
    return <LoadingSpinner />;
  }

  const explanationText =
    confirmedContestantId === ""
      ? `Select winning contestant of ${pool.name} to confirm.`
      : "You have confirmed a winner for this pool.";

  return (
    <>
      <Headroom>
        <Header />
        <PoolSummary pool={pool} backUrl="/confirm-results" betType="win" />
        <Alert variant="warning">{explanationText}</Alert>
      </Headroom>

      <Representer
        confirmedContestantId={confirmedContestantId}
        setConfirmedContestantId={setConfirmedContestantId}
        entries={entries}
        poolId={pool.id}
      />
    </>
  );
};

export default Container;
