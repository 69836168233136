import OpenSansText from "components/custom-texts/open-sans-text";

export const LineBreak = () => {
  return <hr style={{ margin: 5 }} />;
};

const CONTENT = [
  "This application hosts wagering pools with other real players.",
  "Like horse-racing (pari-mutuel) your odds are not finalized until the pool closes, usually around the start time of the event.",
];

const Highlights = () => {
  return (
    <>
      {CONTENT.map((string, index) => {
        const isLast = index === CONTENT.length - 1;
        return (
          <div style={{ paddingLeft: 10, paddingRight: 10 }} key={index}>
            <OpenSansText>{string}</OpenSansText>
            {!isLast && <LineBreak />}
          </div>
        );
      })}
    </>
  );
};

export default Highlights;
