import OpenSansText from "components/custom-texts/open-sans-text";
import { LineBreak } from "./highlights";
import darkStyles from "../dark-styles.module.css";

import Accordion from "react-bootstrap/Accordion";
import AccordionHeader from "../accordion/accordion-header";
import { useAppSelector } from "redux/hooks";
import { sparketBlack } from "utils/constants";
import { getGroupSecondaryColorOrDefault } from "redux/slices/group-slice";

interface PageFuctionalityContent {
  title: string;
  body: string[];
}

const PAGE_FUNCTIONALITY_CONTENT: PageFuctionalityContent[] = [
  {
    title: "Home Page",
    body: [
      "You can access the home page anytime by selecting ‘Home’ from the toolbar on the left.",
      "The home page shows Open pools available to take wagers. It shows the name of the pool, the close time of the pool, and the active bets.",
      "Clicking on the arrow icon ’>’ or in the rectangle of a pool brings you to a Pool Page.",
      "You can access the menu items on the left panel of the home page, and your profile page on the top right. On a mobile view, you may have to click the menu icon (3 horizontal lines) at the top left to expand the menu.",
    ],
  },
  {
    title: "Pool Page",
    body: [
      "The Pool Page will display the pertinent information for an individual wagering pool. You can reach this page by clicking the arrow icon ‘>’ (or in the rectangle of a pool) from the Home Page or ‘View Pool’ from the ‘Betslip’.",
      "In the upper section, you can see the pool name, the amount of active bets on the pool, the pool status, the pool close time, the pool win condition, and a popup to display full rules for the pool.",
      "In the lower section, you can view each contestant in the pool, including their current odds and Active Bets. You can also click the arrow icon ‘>’ (or in the rectangle of a contestant) to proceed to the Place Bet page if the pool is Open.",
      "You can share the pool with friends via any external messaging or social media platform by clicking the ‘copy link’ icon in the top right. You can navigate back to the home page via the ‘back home’ button on the top left.",
      "If the pool status is In Progress or Settled, you will not be able to place a Bet, but you will be able to view the final Odds and Active Bets on each contestant.",
      "If the pool status is Settled, you will be able to see the winner and the outcome (score).",
    ],
  },
  {
    title: "Place Bet Page",
    body: [
      "This page is reached by clicking the arrow ‘>’ or within the rectangle of a specific contestant on the Pool Page and allows you to initiate a wager on that contestant in the pool.",
      "The top section displays the relevant details on the pool from the Pool Page, and the X in the top right allows you to return to the Pool Page.",
      "Next to the pool name, the contestant that you have chosen appears.",
      "Below you see your available funds and may enter the amount you wish to wager.",
      "The current odds and estimated winnings section shows your payout. Odds on your bet are displayed as Decimal Odds and are updated in real time based on the impact your wager has on the overall pari-mutuel pool.",
      "Selecting the ‘Place Bet’ button will allow you to proceed to the Confirm Bet Page.",
    ],
  },
  {
    title: "Confirm Bet Page",
    body: [
      "The Confirm Bet Page is reached by selecting ‘Place Bet’ from the Place Bet Page. Here you can see a preview of your Bet Summary and finalize your bet.",
      "The top section displays Pool information and allows you to navigate back to the Place Bet Page via the ‘Edit Bet’ icon at the top left or back to the Pool Page via the X in the top right.",
      "The Bet Summary shows which contestant you’ve selected, the Current Odds, the Bet Amount, and your Projected Payout.",
      "Below the ‘Confirm Bet’ button, you can read the legal terms of the wager.",
      "Clicking ‘Confirm Bet’ brings you to the Bet Confirmation Page.",
    ],
  },
  {
    title: "Bet Confirmation Page",
    body: [
      "The Bet Confirmation Page is reached from clicking ‘Confirm Bet’ on the Confirm Bet Page.",
      "You can open the ‘Betslip’ page to see more details about your bet by clicking the ‘here’ link.",
      "You can click outside of the Bet Confirmation page to close it.",
    ],
  },
  {
    title: "Betslip",
    body: [
      "You can view the Betslip to review details on any bets that you’ve made by clicking the Arrow icon ‘>’(or in the rectangle of a pool) on the My Bets page or by clicking the ‘here’ link on the Bet Confirmation Page.",
      "You can view the Group that you made the bet in, the pool, the event start and end time, the contestant you bet on (your bet), the estimated odds, your user id, your bet id, the time you place the bet, your bet amount, and your Estimated or Actual winnings.",
      "You can select the ‘View Pool’ button to go to the Pool Page.",
      "You can click outside of the Betslip to return to the previous screen.",
      "If the Pool is Settled, you will find the amount you won if you wagered on the correct contestant, or the amount you lost if you did not.",
    ],
  },
  {
    title: "Review Bet Page",
    body: [
      "The Review Bet Page is reached from clicking ‘Confirm Bet’ on the Review Bet Page, ‘View’ on the My Bets Page, or ‘Go’ from the Bankroll Page.",
      "The page allows you to copy a link to share the pool with The Social Betwork.",
      "You can review your bet and see the pool status, the pool name, the selected contestant, your odds, the Bet Amount, and your Estimated or Actual winnings.",
      "You can select the ‘View Pool’ button to go to the Pool Page.",
      "You can select the ‘Keep Betting’ button to return to the Home Page to view more pools.",
      "If the Pool is Settled, you will see the winning contestant in the pool and the amount you won if you wagered on that contestant, or the amount you lost if you did not.",
    ],
  },
  {
    title: "My Bets Page",
    body: [
      "The My Bets Page can be accessed via the ‘My Bets’ menu  item on the left panel.",
      "From this page you can view each wager you have made, including the contestant and pool name, the pool close date, the amount of your wager, the status of the pool or your bet, and either Estimated Winnings or the actual amount won or lost.",
      "In Progress wagers are highlighted in yellow; winning wagers in green, losing wagers in red; and open wagers are not highlighted.",
      "You can select the arrow icon ‘>’ or within the rectangle of the bet navigate to that wager’s Betslip.",
    ],
  },
  {
    title: "Confirm Results Page",
    body: [
      "The Confirm Results Page can be accessed via the ‘Confirm Results’ menu item on the left panel.",
      "On this page you can view all pools which are ‘In Progress’ and report the results of those pools.",
      "You can proceed to the ‘Confirm Results Pool Page’ by clicking on the arrow icon ‘>’ (or within the rectangle of the pool).",
    ],
  },
  {
    title: "Confirm Results Pool Page",
    body: [
      "The Confirm Results Pool page can be accessed by clicking on the arrow icon ‘>’ or in the rectangle of a pool ‘Confirm Results’ page.",
      "On this page, you will see the same information as appears on the ‘Pool Page’ (see above).",
      "However, on this page when clicking on the arrow icon ‘>’ or within the rectangle of a contestant, it will ask you whether you want to ‘Confirm’ the contestant as the winner.",
      "Clicking ‘Confirm’ submits your report that this contestant was the winner of the pool. This is separate from any wagers.",
      "Clicking on another contestant rectangle or arrow icon before clicking ‘Confirm’ will instead ask you whether to confirm the new contestant as the winner.",
      "If you have already confirmed a winner for that pool, your confirmed result will appear with an orange check mark and highlight within the Confirm Results Pool Page.",
    ],
  },
  {
    title: "Help Page",
    body: [
      "The Help Page can be accessed via the ‘Help’ menu item on the left panel.",
      "This is the section that you’re currently reading! It contains useful information about every function that the application uses, terminology about wagering, responsible gaming information, contact details, supported browsers and more!",
    ],
  },
  {
    title: "Miscellaneous",
    body: [
      "Functionality in this web application is the same on desktop and mobile devices. However, if your screen is a certain size, you may have to select the menu icon on the top left (three vertical lines), in order to see the left panel menu items referenced above.",
    ],
  },
];

const BodyContent = ({ content }: any) => {
  return (
    <>
      {content.map((paragraph: string, index: number) => {
        const isLast = index === content.length - 1;
        return (
          <div style={{ paddingLeft: 10, paddingRight: 10 }} key={index}>
            <OpenSansText>{paragraph}</OpenSansText>
            {!isLast && <LineBreak />}
          </div>
        );
      })}
    </>
  );
};

const PageFunctionality = ({ isActive }: any) => {
  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);

  return (
    <>
      <Accordion>
        {PAGE_FUNCTIONALITY_CONTENT.map((page, index) => {
          return (
            <div key={index}>
              <AccordionHeader
                eventKey={index.toString()}
                styles={darkStyles}
                backgroundColor={sparketBlack}
                color={secondaryColor}
              >
                {page.title}
              </AccordionHeader>
              <Accordion.Collapse eventKey={index.toString()}>
                <BodyContent content={page.body} />
              </Accordion.Collapse>
            </div>
          );
        })}
      </Accordion>
    </>
  );
};

export default PageFunctionality;
