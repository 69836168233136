import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { Helmet } from "react-helmet";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { ProvideAuth } from "hooks/auth";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "components/scroll-to-top";
import { useAppSelector } from "redux/hooks";
import { getGroupSecondaryColorOrDefault } from "redux/slices/group-slice";


const ToastContainerWithStyles = () => {
  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);
  return (
    <ToastContainer
      position="top-right"
      autoClose={7000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss
      pauseOnHover
      toastStyle={{
        background: "#1c2a36",
        color: "white",
        opacity: 1,
        border: `.5px solid ${secondaryColor}`,
      }}
    />
  );
};
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ProvideAuth value={{ signedIn: true, user: {}, pending: false }}>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0"
          />
        </Helmet>
        <Router>
          <ScrollToTop />
          <App />
          <ToastContainerWithStyles />
        </Router>
      </ProvideAuth>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
