// Hook (auth.js)
import { useState, useEffect, useContext, createContext } from "react";
import Amplify, { Auth, Hub } from "aws-amplify";
import { authConfig } from "hooks/authConfig";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { User } from "interfaces/user";
import { useAppDispatch } from "redux/hooks";
import { toast } from "react-toastify";
import { SMS, SMS_MFA } from "utils/constants";
import { getRequest, postRequest } from "utils/httpClient";
import {
  getFailedLoginPath,
  getGroupDetailsPath,
} from "utils/backend-path-builders";
import { useHistory } from "react-router-dom";
import { setGroup } from "redux/slices/group-slice";

Amplify.configure(authConfig);

const authContext = createContext({
  signedIn: false,
  user: {},
  pending: false,
});

export const useAuth = () => {
  return useContext(authContext);
};

export const ProvideAuth = authContext.Provider;

export function useProvideAuth() {
  const defaultAuth = {
    user: null,
    pending: true,
    signedIn: false,
  };

  return {
    user: null,
    pending: false,
    signedIn: true,
    amplifyAuth: Auth,
  };
}
